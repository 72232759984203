/**
 * @generated SignedSource<<5a1881a2102d17d321ff332175b71f27>>
 * @relayHash 037d7144ea8a8c2fabde52b2c9af03c9
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/491.0.0-2024-12-11T17:51:15.109Z/ProductCarouselRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductCarouselRefetchQuery$variables = {
  fetchVideo: boolean;
  itemId: ReadonlyArray<string | null>;
  photosLimit?: number | null;
  viewInRoomLimit?: number | null;
};
export type ProductCarouselRefetchQuery$data = {
  readonly viewer: {
    readonly itemRead: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProductSwiper_item">;
    } | null> | null;
  };
};
export type ProductCarouselRefetchQuery = {
  response: ProductCarouselRefetchQuery$data;
  variables: ProductCarouselRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchVideo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "photosLimit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewInRoomLimit"
},
v4 = [
  {
    "kind": "Variable",
    "name": "itemIds",
    "variableName": "itemId"
  }
],
v5 = {
  "kind": "Literal",
  "name": "addLabel",
  "value": false
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductCarouselRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "itemRead",
            "plural": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "fetchVideo",
                        "variableName": "fetchVideo"
                      },
                      {
                        "kind": "Variable",
                        "name": "photosLimit",
                        "variableName": "photosLimit"
                      },
                      {
                        "kind": "Variable",
                        "name": "viewInRoomLimit",
                        "variableName": "viewInRoomLimit"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ProductSwiper_item"
                  }
                ],
                "type": "Item",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductCarouselRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "itemRead",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemMeasurement",
                    "kind": "LinkedField",
                    "name": "measurement",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "unit",
                            "value": "IN"
                          }
                        ],
                        "concreteType": "ItemConvertedMeasurementsType",
                        "kind": "LinkedField",
                        "name": "convertedMeasurements",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          }
                        ],
                        "storageKey": "convertedMeasurements(unit:\"IN\")"
                      },
                      {
                        "alias": "widthDimensions",
                        "args": [
                          (v5/*: any*/),
                          {
                            "kind": "Literal",
                            "name": "fields",
                            "value": [
                              "width"
                            ]
                          }
                        ],
                        "concreteType": "ItemDisplayMeasurementType",
                        "kind": "LinkedField",
                        "name": "display",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": "display(addLabel:false,fields:[\"width\"])"
                      },
                      {
                        "alias": "heightDimensions",
                        "args": [
                          (v5/*: any*/),
                          {
                            "kind": "Literal",
                            "name": "fields",
                            "value": [
                              "height"
                            ]
                          }
                        ],
                        "concreteType": "ItemDisplayMeasurementType",
                        "kind": "LinkedField",
                        "name": "display",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": "display(addLabel:false,fields:[\"height\"])"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceId",
                    "storageKey": null
                  },
                  {
                    "alias": "carouselPhotos",
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "limit",
                        "variableName": "photosLimit"
                      }
                    ],
                    "concreteType": "ItemPhoto",
                    "kind": "LinkedField",
                    "name": "photos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "placeholder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "smallPath",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "masterOrZoomPath",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "fetchVideo",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasApprovedVideo",
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "limit",
                        "variableName": "viewInRoomLimit"
                      }
                    ],
                    "concreteType": "viewInRoomPhotos",
                    "kind": "LinkedField",
                    "name": "viewInRoomPhotos",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "src",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "viewInRoomPhotosCenterPosition",
                        "kind": "LinkedField",
                        "name": "centerPosition",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "top",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "left",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "viewInRoomPhotosComparatorWidth",
                        "kind": "LinkedField",
                        "name": "comparatorWidth",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pixel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "inches",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Item",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/491.0.0-2024-12-11T17:51:15.109Z/ProductCarouselRefetchQuery",
    "metadata": {},
    "name": "ProductCarouselRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "584b05c0c3dee4e6bd5023d45213c905";

export default node;
