const SEGMENTS = {
    SELLER: 'dealers',
    EXCLUSIVE: 'exclusive',
};

const VERTICALS = {
    FURNITURE: 'furniture',
    ART: 'art',
    JEWELRY: 'jewelry',
    FASHION: 'fashion',
};

export const ROUTING_SEGMENT_MOBILE = 'mobile';
export const ROUTING_SEGMENT_RESPONSIVE = 'resp';

export { SEGMENTS, VERTICALS };
