/**
 * @generated SignedSource<<0cdd8f3a348566766e97d33ad74cea18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwiperItem_photo$data = {
  readonly masterOrZoomPath: string | null;
  readonly placeholder: string | null;
  readonly smallPath: string | null;
  readonly " $fragmentType": "SwiperItem_photo";
};
export type SwiperItem_photo$key = {
  readonly " $data"?: SwiperItem_photo$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwiperItem_photo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwiperItem_photo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "smallPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masterOrZoomPath",
      "storageKey": null
    }
  ],
  "type": "ItemPhoto",
  "abstractKey": null
};

(node as any).hash = "34c12a755b941c91ba57f76a5619775a";

export default node;
